import React from "react"
import {Row, Col, Container} from "react-bootstrap"
import SEO from "../components/seo"
import ValuationCTA from "../components/valuationCta";
import Layout from "../components/layout";
import {graphql} from "gatsby";

const TestimonialsPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="Testimonials | iCashCars"  description="By using us, you will receive only the best in customer service, the absolute highest price for your vehicle and great inside industry knowledge." />

            <Container className="slice">
                <h1 className="text-center font-weight-800 mb-5">What iCashCars Customers Think</h1>

                <p>
                    Here at iCashCars, we pride ourselves on being highly professional vehicle valuers exceeding 20 years experience in the field. We assess and evaluate every car individually rather than offering a general automated price.
                </p>
                <p>
                    We recognise that each car is different and that the trends and popularities of cars vary in different areas of the UK which many valuers don’t take into consideration. An example of this is if someone is choosing to sell a 4x4 car up in Scotland, the valued selling price is most likely to be higher than if you were to sell the same 4x4 in say, central London. Vice versa if you were selling a compact town car in London rather than in the highlands of Scotland where it may not be much use.
                </p>
                <p>
                    At iCashCars, our knowledge of cars and the industry is expert. By using us, you will receive only the best in customer service, the absolute highest price for your vehicle and great inside industry knowledge.
                </p>

                <Row className="mt-5">
                    <Col lg={4}>
                        <div className="bg-gray-100 p-3 rounded position-relative mb-4 mb-lg-0">
                            <span className="display-3 font-weight-800 text-primary-dark position-absolute mt-n4">“</span>
                            <p className="font-italic mt-4">Recently sold my second hand vehicle to these chaps. Very fair price , fantastic service ! Helped me out in a time of need ! Highly recommended !!
                            </p>
                            <h5 className="text-right text-primary-dark">Levi James</h5>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="bg-gray-100 p-3 rounded position-relative mb-4 mb-lg-0">
                            <span className="display-3 font-weight-800 text-primary-dark position-absolute mt-n4">“</span>
                            <p className="font-italic mt-4">Awesome service from the guys at iCashCars. Happy with the quote and how easy it was to sell my car.
                            </p>
                            <h5 className="text-right  text-primary-dark">Sam Johnson</h5>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="bg-gray-100 p-3 rounded position-relative mb-4 mb-lg-0">
                            <span className="display-3 font-weight-800 text-primary-dark position-absolute mt-n4">“</span>
                            <p className="font-italic mt-4">Great job, I will definitely be use you again should I need to! Thanks guys, keep up the good work!
                            </p>
                            <h5 className="text-right  text-primary-dark">Peter Addison</h5>
                        </div>
                    </Col>
                </Row>
            </Container>

            <ValuationCTA></ValuationCTA>

        </Layout>
    )
}


export const data = graphql`
    {
          file(relativePath: { eq: "sara-banner-2.png" }) {
              childImageSharp {
                fluid {
                        ...GatsbyImageSharpFluid
                  }
              }
        }
    }
`

export default TestimonialsPage
