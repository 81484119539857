import React from "react"

const ValuationCTA = () => {
    const handleOnSubmit = e => {
        e.preventDefault();
        let vrm = document.getElementById("vrmValuation").value;
        vrm = vrm.replace(/\W/g, '');
        window.location.href = "https://icashcars.co.uk/value-my-car/" + vrm;
    };
    return (
        <>

        </>
    )
}

export default ValuationCTA
